.Stick {
  position: absolute;
  background-color: #d00e0e;
  left: 0;
  z-index: 1;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: inherit;
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 14px;
  margin: 5px;

  &--ORIG{background-color:#61bd4f;color:#fff}
  &--POP{background-color:#7775f9;color:#fff}
  &--NEED{background-color:#772f82;color:#fff}
  &--BEST{background-color:#e05151;color:#fff}
  &--CEKA{background-color:forestgreen;color:#fff}
  &--REC{    background-color: #ffe900;
    color: #000;}
}
