.Item {
    text-align: left;
    padding: 10px 0;
    color: #000;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.Ava {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #343a40;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    margin-right: 15px;
}

.Name {
    float: left;
    margin-right: 10px;
    font-weight: 600;
    margin-bottom: 5px;
}

.Date {
    float: left;
    font-size: 14px;
    margin-top: 1px;
    color: #7f7f7f;
    text-align: right;

}

.Text {
    float: left;
    width: calc(100% - 65px);
    word-break: break-word;
}

.Answer {
    float: right;
    background-color: #f0f0f0;
    padding: 10px 20px;
    width: calc(100% - 65px);
    margin-top: 15px;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .Author {
        width: 100%;
        margin-bottom: 20px;
    }
    .Block {
        width: 100%;
    }
}


@media only screen and (max-width: 768px) {


    .Ava {
        width: 40px;
        height: 40px;
        line-height: 40px;
        min-width: 40px;
    }
}

:global(.popup) {
 
    .Ava {
        width: 40px;
        height: 40px;
        line-height: 40px;
        min-width: 40px;
    }
}