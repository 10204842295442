.Adv {
  display: inline-block;
  h2{
    margin-bottom: 20px;
  }
}

.AdvList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  > div {
    display: flex;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 25px 46px;
    gap: 20px;
    > span {
      line-height: 28px;
      font-size: 18px;
    }
  }
}

.AdvListRow_2 {
  grid-template-columns: 1fr 1fr;
}

.AdvListRow_3 {
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 991px) {
  .AdvListRow_3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .AdvList > div {
    padding: 25px 5px;

    > span {
      line-height: 24px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .AdvListRow_3 {
      > div {
        padding: 15px 5px;
    
          > span {
            line-height: 20px;
            font-size: 14px;
            text-align: center;
          }
      }
  }
  .AdvListRow_2 {
    > div {
      padding: 15px 5px;
  
        > span {
          line-height: 20px;
          font-size: 14px;
          text-align: center;
        }
    }
}
  .AdvList > div {
    flex-direction: column;
  }
}