.BalloonPoint {
  max-width: 400px;

  tr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  td {
     padding: 5px;
  }
}