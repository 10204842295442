.Top {
  display: flex;
  justify-content: space-between;
  color: #9294A9;

  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 22px;
  margin-bottom: 20px;


  &Baltex {
    position: absolute;
    top: 20px;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
  }
}

.Article {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.Communicate {
  display: flex;
  font-size: 14px;

  > div {
    margin-left: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }
}



@media only screen and (max-width: 550px){
    .Top {
        padding-bottom: 10px;
        margin-bottom: 10px;
        flex-direction: column;
        gap: 10px;
    }

    .Communicate > div:first-child {
        margin: 0;
    }
}