.Buttons {
  display: flex;
  width: 100%;
  gap: 20px;
  > div {
    width: 50%;
  }
}

.Content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Change {
  display: inline-block;
  color: var(--main-theme);
  cursor: pointer;
}

.Error {
  color: red;
}