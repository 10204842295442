.Search {
    flex: 1 1;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    display: flex;

    align-items: center;
    height: 48px;
    justify-content: space-between;
    margin-bottom: 20px;

    position: relative;
    input {
        border: none;
        outline: none;
        font-size: 16px;
        color: #9294A9;
        padding: 14px 18px;
        border-radius: 4px;
        width: 100%;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                border: none;
            }
        }
    }

    svg {
        margin: 0 15px;
    }

    ul {
        box-shadow: 0px 10px 30px rgb(0 13 37 / 15%);
        border-radius: 8px;
        position: absolute;
        top: 54px;
        background-color: #fff;
        width: 100%;
        overflow: hidden;

        li {
            padding: 15px 20px;
            cursor: pointer;
            transition: 0.3s;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: #EEF0FC;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.Citys {
  margin: 0;

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 600px;
    height: 280px;

    li {
      padding: 5px 0;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.Title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.Close {
    cursor: pointer;
    display: flex;
}

@media only screen and (max-width: 768px) {
    .Citys ul{
        width: calc(100vw - 200px);
        height: 350px;
    }
}

@media only screen and (max-width: 550px) {
    .Citys ul{
        width: calc(100vw - 100px);
        height: inherit;
    }
}