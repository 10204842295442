.Services {
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    > div {
      background: #EEF0FC;
      gap: 20px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 28px 30px;
      background-repeat: no-repeat;
      background-position-x: 100%;
    }
  
      > a {
          display: flex;
          gap: 20px;
          color: #333;
      }
  
      img {
        border: 1px solid #D8D8D8;
        border-radius: 8px;
        max-width: 120px;
    }


    &Baltex {
      flex-direction: initial;
      flex-wrap: wrap;
      > a {
        background-color: #fff;
        width: 49%;
        padding: 20px;
        align-items: center;


        img {
          border: none;
        }
      }
    }
  }
  
  .Name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      display: inline-block;
      width: 100%;
  }
  
  .Title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .Text {
      font-size: 15px;
      line-height: 24px;
      color: #0F1323;
      max-width: 450px;
      display: inline-block;
  }
  
  .Price {
    font-size: 18px;
    font-weight: bold;
  }
  
  .Buttons {
    max-width: 600px;
    display: flex;
    gap: 10px;
  }
  
  @media only screen and (max-width: 991px) {
      .Services {
          margin-bottom: 20px;
          img {
              max-width: 80px;
          }

          &Baltex {
            > a {
              width: 100%;
            }
          }
      }
      .Text {
          font-size: 14px;
          line-height: 1.5em;
      }
  }