.Services {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  > a {
    display: flex;
    gap: 20px;
    color: #333;

    background-color: #EEF0FC;
    padding: 30px;
    border-radius: 8px;
    position: relative;

    > div {
      max-width: 60%;
    }

  }

  img {
    position: absolute;
    border-radius: 8px;
    right: 10px;
    top: 10px;
  }
}

.Name {
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.Title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.Text {
  font-size: 15px;
  line-height: 24px;
  color: #0F1323;
  max-width: 450px;
  display: inline-block;
}

.Link {
  margin-top: 10px;
  font-size: 14px;
  display: block;
  color: var(--link-color);
}

.Price {
  font-size: 18px;
  font-weight: bold;
}

.Buttons {
  max-width: 600px;
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 991px) {
  .Services {
    margin-bottom: 20px;

    img {
      max-width: 80px;
    }
  }
  .Text {
    font-size: 14px;
    line-height: 1.5em;
  }
}

.ServicesContainerLanding {
  background: radial-gradient(99.4% 99.4% at 1.33% 0.6%, #2B3F4E 0%, #2A3B48 67.49%, #212E38 100%);
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px 30px;

  .Title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border-bottom: solid 1px rgba(255, 255, 255, .2);
    margin: 0;
    padding-bottom: 20px;
  }

  .Services {
    a {
      justify-content: end;
      flex-direction: row-reverse;
      background-color: unset;
      padding: 15px 0;
      gap: 30px;
      border-bottom: solid 1px rgba(255, 255, 255, .2);
      border-radius: 0;

      div {
        max-width: 90%;
        width: 90%;
      }

      &:last-child {
        border-bottom: 0;
      }

      @media (max-width: 991px) {
        flex-direction: column-reverse;
      }
    }

    .ImgLanding {
      width: 10%;
    }

    span {
      color: #fff;
    }

    img {
      position: unset;
    }

    .Text {
      color: #BBBFC2;
    }
  }
  hr {
    width: 100%;
    opacity: 20%;
  }
  hr:last-child {
    display: none;
  }
}

.ArrowLanding {
  display: flex;
  align-items: baseline;
  max-width: 10% !important;
  width: 10% !important;
  justify-content: end;

  @media (max-width: 991px) {
    position: absolute;
    right: 0;
    top: 50%;
  }
}