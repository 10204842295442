.Delivery {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
  line-height: 22px;

  > div {
    &:first-child{
      font-weight: bold;
    }

    &:last-child{
      display: flex;
      color: var(--main-theme);
      grid-gap: 10px;
      gap: 5px;
      cursor: pointer;
      align-items: center;
    }
  }

  &Baltex {
    > div {
      &:last-child {
       /* color: var(--link-color);*/
      }
    }

    svg {
      display: none;
    }
  }
  &Landing {


    .Link {
      font-size: 13px;
      color: #4E74FA !important;
    }
  }
}