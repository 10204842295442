.Item {
    text-align: left;
    padding: 10px 0;
    color: #000;
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}

.Author {
    float: left;
    width: 20%;
    display: flex;
    flex-direction: column;
}

.Loader {
    display: flex;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

.Block {
    float: left;
    width: 80%;
    background-color: #fbfbfb;
    padding: 40px;
    position: relative;
}

.Text {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    word-break: break-word;

    > span {
        display: inline-block;
        width: 100%;
        font-weight: 600;
        margin-bottom: 5px;
    }
}

.Comment {
    float: right;
    text-align: right;
    display: flex;
    gap: 15px;
}

.CommentButton {
    color: var(--main-theme);
    cursor: pointer;
}

.Ava {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #343a40;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
    min-width: 50px;
}

.Name {
    float: left;
    margin-right: 10px;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.Date {
    float: left;
    font-size: 14px;
    margin-top: 1px;
    color: #7f7f7f;
}

.Rating {

}

.Comments {
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #cacaca;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .Author {
        width: 100%;
        flex-direction: row;
        gap: 20px;
    }
    .Block {
        width: 100%;
        padding: 20px;
    }

    .Comments {
        margin-top: 20px;
    }

    .Ava {
        width: 40px;
        height: 40px;
        line-height: 40px;
        min-width: 40px;
    }
}

:global(.popup) {
    .Author {
        width: 100%;
        flex-direction: row;
        gap: 20px;
    }
    .Block {
        width: 100%;
        padding: 20px;
    }

    .Comments {
        margin-top: 20px;
    }

    .Ava {
        width: 40px;
        height: 40px;
        line-height: 40px;
        min-width: 40px;
    }
}