.Product {
    display: flex;
    gap: 15px;
    background-color: #EEF0FC;
    color: #333;
    padding: 10px;
    border-radius: 10px;
    max-width: 600px;
    &Photo {
        width: 100px;
        img {
            border-radius: 5px;
        }
    }
    &Info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span:last-child {
            font-weight: 600;
        }
    }
}