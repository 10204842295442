.Items {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.Item {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D8D8D8;
  gap: 20px;

  align-items: center;
  &:first-child{
    border-top: none;
  }
  &:last-child{
      border-bottom: 1px solid #D8D8D8;
  }
}

.Total {
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgb(0 13 37 / 15%);
  border-radius: 8px;
  padding: 28px 24px;
  min-width: 300px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

}

.TotalItem {
  display: flex;
  justify-content: space-between;
}

.TotalItemFinal {
  font-size: 20px;
}

.Photo {
  color: #282f36;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  margin: 20px 0;
  width: 90px;
  height: 90px;
  min-width: 90px;
  display: flex;
  align-items: center;
}

.Other {
    display: grid;
    grid-template-columns: 6fr 142px 2fr;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
  width: 100%;
}

.Name {
    display: flex;
    flex-direction: column;
    gap: 10px;
  a{
      color: #282f36;
  }
}

.Qty {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  height: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    display: flex
  }

  div {
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;
    padding: 10px;
    justify-content: center;

  }
}

.Empty {
  font-size: 20px;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Loader {
    display: flex;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

.Delete {
  display: flex;
  cursor: pointer;
}

.PriceMobile {
    display: none;
    font-weight: 600;
}

.PriceMobileOld {
  display: none;
}

.PriceOld {
  font-size: 14px;
  text-decoration-line: line-through;
  color: #93979b;
}

.PriceMobileOld {
  font-size: 14px;
  text-decoration-line: line-through;
  color: #93979b;
}

.Electric {
  background: var(--main-theme);
  color: #fff !important;
  border: 1px solid var(--main-theme);
  padding: 2px 5px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
  width: fit-content;
}

@media only screen and (max-width: 991px) {
    .Total {
        box-shadow: none;
        padding: 0;
        margin: 0;
    }

    .Items {
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    .Item {
        grid-template-columns: 70px 5fr 142px 2fr;
    }
    .Photo {
        width: 70px;
        min-width: 70px;
        height: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .Other {
        grid-template-columns: 5fr 142px;
    }
    .Price {
        display: none;
    }
    .PriceMobile {
      display: block;
  }
  .PriceMobileOld {
    display: block;
  }

    .Qty {
        width: 120px;
        height: 38px;
    }
}

@media only screen and (max-width: 500px) {
    .Photo {
        margin: 0;
    }
    .Other {
        grid-template-columns: 1fr;
        padding: 20px 0;
        gap: 15px;
    }
    .Price {
        display: none;
    }

}