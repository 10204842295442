.Buttons {
  display: flex;
  width: 100%;
  gap: 20px;
  margin: 25px 0 20px;
  > div {
    width: 50%;
  }
}

.Content {
    max-width: 380px;
}

.Change {
  margin: -10px 0 10px;
  display: inline-block;
  color: var(--main-theme);
  cursor: pointer;
}

.Error {
  color: red;
}