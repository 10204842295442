.Loader {
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: center;
}

.Wrapper {
  max-width: 1000px;
  min-width: 700px;
}