.Content {
    width: 600px;
    height: 400px;
    max-width: 100%;

  iframe {
      margin-top: 30px;
      width: 100%;
      height: calc(100% - 30px);
  }
}