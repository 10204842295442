.Buttom {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    > div {
        flex: 1;
    }
}

.Error {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
    max-width: 400px;
}

.TopText {
    max-width: 400px;
}

.Content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Loader {
    display: flex;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

.Privacy {
    color: #9294A9;
    line-height: 20px;
    display: flex;
    max-width: 400px;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
}

@media only screen and (max-width: 520px) {
    .Content {
        gap: 15px;
    }
    .Privacy {
        font-size: 13px;
    }
    .Buttom {
        margin-top: 20px;
    }
}