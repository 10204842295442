.Checkbox {
    display: flex;
    gap: 15px;
    cursor: pointer;
    
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .Box {
                border-color: var(--main-theme);
            }
        }
    }
}

.Box {
    width: 20px;
    height: 20px;
    border: 1px solid #D5D7E6;
    border-radius: 5px;
    transition: 0.3s;

    &Checked {
        background-color: var(--main-theme);
        border-color: var(--main-theme);
        position: relative;
        &:before {
            position: absolute;
            content: '';
            left: 3px;
            top: 4px;
            width: 9px;
            height: 5px;
            border-radius: 1px;
            border-left: 3px solid #fff;
            border-bottom: 3px solid #fff;
            transform: rotate(-45deg);
        }
    }
}