.Item {
  margin: 20px 0;
  width: 100%;
  display: inline-block;
}

.Frame {

  iframe {
    max-width: 750px;
    height: 1000px;
    width: 100%;
    border: 1px solid #e7e8e8;
    border-radius: 8px;
  }
}

.Button {
  font-size: 20px;
  border: 1px solid var(--main-theme);
  border-radius: 10px;
  margin: 1%;
  width: 48%;
  float: left;
  color: #282f36;
  padding: 20px 20px 10px;
  background-color: #fff;
  height: 175px;
  text-align: center;
}

.Qnt {
  font-size: 14px;
  color: #93979b;
}

@media only screen and (max-width: 500px) {
    .Button {
        width: 98%;
        height: inherit;
    }
}

.Contacts {
  display: flex;
  margin-bottom: 50px;
  flex-direction: column;
}


.Info {
    display: flex;
    gap: 40px;
    margin: 30px 0;

    > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.Map {
    width: 100%;
    height: 500px;
    border-radius: 8px;
}

.Requisite {
    > div {
  display: flex;
  margin: 0 0 50px 0;
  padding: 50px 0;
  gap: 30px;

  > div {
    width: 50%;
  }

    h2 {
      font-size: 18px;
      margin: 0;
      padding: 18px 0;
  }

  p {
    margin: 0;
    color: #9294A9;
    border-top: 1px solid #D5D7E6;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 15px;

    span:first-child {
    }

    span:last-child {
        color: #0F1323;
        text-align: right;
    }


    &:last-child {
        border-bottom: 1px solid #D5D7E6;
    }
  }
}
}

.AllShops {
  margin-bottom: 50px;
  > div {
    display: flex;
    flex-direction: column;
  }
}

.Adreses {
    display: flex;
    z-index: 1;

}

.Adr {
    width: 400px;
    margin-right: -400px;
    z-index: 1;
    padding: 70px 0 0 50px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.AdrBlock {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 13, 37, 0.15);
    border-radius: 8px;

    padding: 20px;
    font-size: 15px;

    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    > div {
        color: #9294A9;
    }

    > span {
        margin-bottom: 5px;
    }
}

.Scheme {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.SchemeTop {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  color: #9294A9;
}

.SchemeSelected {
    color: #1247a5!important;
}

.Images {

}

.Blocks {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.Video {
    iframe {
        width: 100%;
        height: 500px;
    }
}
@media only screen and (max-width: 991px) {
    .Adr {
        z-index: 1;
        padding: 0 0 30px;
        display: flex;
        grid-gap: 12px;
        gap: 12px;
        margin-right: 0;
        flex-direction: row;
        width: inherit;
    }

    .Adreses {
        flex-direction: column;
    }

}

@media only screen and (max-width: 768px) {
    .Requisite {
        flex-direction: column;
        padding: 20px 0;

        > div {
            width: 100%;
        }
        p {
            padding: 10px 0;
            font-size: 14px;
        }
    }

    .Contacts {
        margin: 0;
    }

    .Info {
        margin: 0 0 20px;
    }
    .Blocks {
        gap: 30px;
    }

  }

  @media only screen and (max-width: 500px) {
    .Adr {
        flex-direction: column;
    }
  }