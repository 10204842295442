.Slider {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &FullScreen {
        position: fixed;
        width: 100%;
        height: 100%;
        padding: 50px;
        top: 0;
        left: 0;
        flex-direction: row;
        gap: 30px;
        z-index: 30;
        align-items: center;


        .Close {
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 1;
            cursor: pointer;
            transition: 0.3s;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    scale: 1.5;
                }
            }
        }

        .Thumbs {
            z-index: 1;
            flex-direction: column;
            right: 20px;
            top: 20px;
            grid-template-columns: 1fr;
            overflow: auto;
            min-width: 100px;
        }

        .Thumb {
            max-width: 100px;
        }

        /*.Arrow {
            width: 50%;
            justify-content: flex-end;
            padding: 0 20px;
            svg {

            }
        }*/
    }
    &Landing {
        img {
            max-width: unset;
        }
    }
}

.Back {
    position: fixed;
    background-color: #000;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
}

/*
.ImgWrapper {
    transition: 0.3s;
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    svg {
        fill: var(--main-theme);
        position: absolute;
    }
    img {
        transition: 0.3s;
        border-radius: 5px;
        max-height: 100%;
    }

    &Loading {
        img {
            opacity: 0.1;
        }
    }

    &:hover {
        .Arrows {
            opacity: 1;
        }
    }
}*/

.Img {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 12;
    &Loading {
        img {
            opacity: 0.1;
        }
    }
    svg {
        fill: var(--main-theme);
        position: absolute;
    }
    img {
        cursor: zoom-in;
        transition: 0.3s;
        border-radius: 5px;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .Arrow {
                opacity: 1;
                &:first-child{
                    left: 0px;
                    transform: scale(-1, 1)
                }
                &:last-child{
                    right: 0px;
                }
            }
        }
    }
    .ArrowSwiped {
        opacity: 1;
        &:first-child{
            left: 0;
        }
        &:last-child{
            right: 0;
        }
    }
}

.Selectors {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 5px);
    gap: 4px;
    z-index: 1;
}

.Selector {
    width: 100%;
    border-bottom: 3px solid #b2b2b2;
}

.ActiveSelector{
    border-color: var(--main-theme);
}

.Thumbs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    //display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    align-items: flex-start;
}

.Thumb {
    border: 2px solid #fff;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    aspect-ratio: 16/12;
    justify-content: center;
    background-size: cover;
    background-position: 100%;
    img {
        border-radius: 3px;
    }

    &Active {
        border-color: var(--main-theme);
    }

    &Video {
        order: 1;
    }
}

.Arrow {
    opacity: 0;
    transition: 0.3s;
    width: 60px;
    height: 60%;
    top: 20%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    svg {
        fill: #1247a5!important;
    }

    &:first-child{
        left: -40px;
        transform: scale(-1, 1)
    }
    &:last-child{
        right: -40px;
    }
}