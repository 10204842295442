.Items {
  display: flex;
  flex-direction: column;
  font-size: 18px;

}

.Basket {
  max-width: 700px;
}

.Item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  gap: 20px;


  align-items: center;
  &:last-child{
      border-bottom: 1px solid #D8D8D8;
  }
}

.ItemPhoto {
  max-width: 150px;
}

.Total {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.TotalItem {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    gap: 5px;
    >div {
        color: var(--main-theme);
    }
}

.TotalButtons {
    display: flex;
    gap: 15px;
}

.TotalItemFinal {
  font-size: 20px;
}

.Photo {
  color: #282f36;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  margin: 20px 0;
  width: 90px;
  height: 90px;
  min-width: 90px;
  display: flex;
  align-items: center;
}

.Name {

  display: inline-block;
  width: 50%;

  a{
      color: #282f36;
  }
}

.Qty {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  width: 142px;
  height: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    display: flex
  }

  div {
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;
    padding: 10px;
    justify-content: center;

  }
}

.Empty {
  font-size: 20px;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Loader {
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Delete {
  display: flex;
  cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .Total {
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
        flex-direction: column;
    }
    .TotalButtons {
        > div {
            flex: 1;
        }
    }
}