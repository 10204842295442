.Files {
    display: flex;
    flex-direction: column;
}

.File {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
    }
}

.Name {
    font-size: 13px;
    color: #0F1323;
    span {
        color: #9294A9;
        font-size: 15px;
    }
}

.FilesLanding {
    margin: 15px 0;
    .File{
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}