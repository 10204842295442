.Share {
    margin-top: 30px;
    > span {
        font-weight: 600;
        color: #0F1323;
        margin-bottom: 20px;
        display: block;
    }

    > div {
        display: flex;
        gap: 10px;
    }

    a {
        cursor: pointer;
    }
}