.Input {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #9294A9;
  }

  > div {
    input, div, textarea {
      border: 1px solid #D8D8D8;
      border-radius: 4px;
      padding: 14px 18px;
      font-size: 16px;
      width: 100%;
    }
  }
}