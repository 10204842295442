.Prop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;

  &:after{
    content: '';
    width: 100%;
    border-bottom: 1px dotted #9294A9;
    position: absolute;
    bottom: 4px;
  }

  &Baltex {
    .PropName {
      background-color: var(--main-theme-back);
    }
    .PropValue {
      background-color: var(--main-theme-back);
    }
  }

  &Landing::after {
    border-bottom: 1px solid #D4DDE3;
  }
}

.PropName {
  color: #9294A9;
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding-right: 5px;

  &Landing {
    padding-right: 25px;
    color: #383D4C;
    font-size: 14px;
  }
}

.PropValue {
  color: #0F1323;
  background-color: #fff;
  z-index: 1;
  padding-left: 5px;
  text-align: right;

  a {
      background: var(--main-theme);
      color: #fff;
      border: 1px solid var(--main-theme);
      padding: 2px 5px;
      font-size: 13px;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 5px;
  }

  &Landing {
    font-size: 14px;
  }
}

.Hint {
  color: var(--main-theme);
  border-radius: 50%;
  width: 15px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  right: -12px;
  top: 0px;
  cursor: pointer;
  &:hover {
    .HintText {
      display: block;
    }
  }
  &Text {
    display: none;
    box-shadow: 0px 0px 5px 1px #c1c1c1;
    padding: 15px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: normal;
    color: #333;
    max-width: 300px;
    min-width: 200px;
    bottom: 15px;
    background-color: #fff;
    position: absolute;
    transition: 0.3s;
  }
  &Landing {
    width: 20px;
    height: 20px;
    right: 0;

  }
}

@media only screen and (max-width: 550px){
    .Prop {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}