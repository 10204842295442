.Brand {

    img {
        width: 120px;
    }

    a {
        padding: 10px;
        color: #333;
        background-color: #EEF0FC;
        display: flex;
        border-radius: 10px;
    }
}