.Jobs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px 10px;
    > div {
        text-align: center;

        img {
            border-radius: 8px;
        }
    
        div {
            font-size: 18px;
            margin: 10px 0 5px;
            color: #111634;
        }
        span {
            font-size: 17px;
            line-height: 20px;
            color: #93979b;
        }
    }
}

@media only screen and (max-width: 768px) {
    .Jobs {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .Jobs {
      grid-template-columns: 1fr 1fr;
    }
  }