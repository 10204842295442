.Delivery {
  display: flex;
  flex-direction: column;
}

.Title {
  font-size: 18px;
  font-weight: 600;
}

.Info {
  margin: 50px 0;
  padding: 20px 0 50px;


  > div {
    z-index: 2;
    position: relative;
  }

  &Landing {
    width: 65%;
    margin: 40px 0 0 0;


    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.Tabs {
  display: flex;
  grid-gap: 10px;
  border-bottom: 2px solid #D8D8D8;
  margin-bottom: 20px;
  gap: 60px;
  font-size: 15px;

  > span {
    cursor: pointer;
    padding: 18px 0 ;

    &.Selected {
      color: var(--main-theme);
      border-bottom: 4px solid var(--main-theme);
      margin-bottom: -2px;
    }
  }
}

.Sections {
    display: flex;
    margin: 30px 0;
    flex-wrap: wrap;
    gap: 18px;
    font-weight: 700;
    line-height: 36px;
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #DFE0E1;
    margin-bottom: 30px;
    > span {
        padding: 10px 0px;
        color: #93979b;
        display: inline-block;
        cursor: pointer;

        &.Selected{
            color: var(--main-theme);
            border-color: var(--main-theme);
        }
    }
}

.Active {
  border-color: #0070f3!important;
}

.Content {


  table {
    width: 100%;
    text-align: left;
  }
}

.Description {

    line-height: 1.5em;

}

.Bottom {
    margin-top: 40px;
    display: flex;
    gap: 40px;

  &Baltex {

  }
}

.Props {
  max-width: 600px;
  float: left;
  width: 50%;
}

.Docs {
  max-width: 600px;
  float: left;
  width: 50%;

  ul {
    padding-left: 15px;
    list-style: decimal;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
      font-size: 15px!important;
    }
  }
}

.SubTitle {
  font-size: 28px;
  margin-bottom: 40px;
}

.FormButton {
    margin-top: 20px;
    max-width: 300px;
}


@media only screen and (max-width: 768px) {
  .Tabs {
    gap: 20px
  }

    .Sections {
      margin: 0;
      font-size: 16px;
      padding: 0;
  }
    .SubTitle {
      font-size: 22px;
      margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
    .Props {
        width: 100%;
        margin: 0;
    }
    .Docs {
        margin-bottom: 0;
        width: 100%;
    }
    .Bottom {
        gap: 20px;
        margin: 10px 0 0;
        flex-direction: column;
    }

    .Sections {
      font-size: 14px;
      padding-bottom: 10px;
      > span {
        padding: 0;
      }
    }
}
.TitleDetailText {
  font-weight: 700;
  font-size: 24px;
}