.Delivery {
  display: flex;
  flex-direction: column;

  h2 {
      font-size: 18px;
  }
}


.Title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;

  span {
      cursor: pointer;
      color: var(--main-theme);
  }
}

.OldPrice {
  text-decoration: line-through;
  margin-right: 5px;
}

.Tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
  flex-wrap: wrap;

  > div {
      cursor: pointer;
      padding: 12px 20px;
      border: 1px solid #D5D7E6;
      border-radius: 8px;
      height: 74px;
      min-width: calc(25% - 15px);

      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: space-around;

      > span {
          color: #9294A9;
      }
  }

}

.Active {
  border-color: #0070f3!important;
}

.Content {

th {
    color: #9294A9;
    font-weight: normal;
}

  table {
    width: 100%;
     margin-bottom: 25px;
    text-align: left;
  }

    tr {
      height: 54px;
      th,td {
          border-bottom: 1px solid #D5D7E6;
      }
  }

    a {
        color: var(--main-theme);
    }
}

.Table {

    .Head {
        color: #9294A9;
        border-bottom: 1px solid #D5D7E6;
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    > span {
        padding: 15px 10px;
        border-bottom: 1px solid #D5D7E6;
        display: flex;
        align-items: center;
    }

    &Small {
        grid-template-columns: 1fr 1fr 1fr;
    }
    &Middle {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &Description {
        margin-top: 20px;
    }
}

.EmptyCity {
  display: flex;
  font-size: 16px;
  align-items: center;
  gap: 20px;
  margin-right: 50px;
}

.FormButton {
  white-space: nowrap;
}

.Loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}

@media only screen and (max-width: 991px) {
    .Tabs > div {
        padding: 8px 10px;
        height: 65px;
        width: calc(25% - 8px);
    }
}

@media only screen and (max-width: 700px) {
    .Tabs > div {
        width: calc(50% - 5px);
    }
    .Tabs {
        margin-bottom: 15px;
    }

    .Title {
        margin-bottom: 10px;
        font-size: 20px;
    }

    .TableButton {
        grid-column-start: 1;
        grid-column-end: 6;
        border-bottom: 1px solid #D5D7E6!important;
        padding-top: 0!important;
    }

    .Table {
        grid-template-columns: 2fr 2fr 3fr 1fr 1fr;
        > span {
            border-bottom: none;
            padding: 10px 5px;
        }
        .HeadLast {
            display: none;
        }
        &First {
            grid-column-start: 1;
            grid-column-end: 3;
        }
        &Small {
            grid-template-columns: 1fr 1fr;

            .TableButton {
                grid-column-end: 3;
            }
        }
        &Middle {
            grid-template-columns: 1fr 1fr 1fr;
            .TableButton {
                grid-column-end: 4;
            }
        }

    }

  .EmptyCity {
    flex-direction: column;
    align-items: flex-start;
  }

}