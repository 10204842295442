.Input {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #9294A9;
  }

  > div {
    input, div, textarea {
      border: 1px solid #D8D8D8;
      border-radius: 4px;
      padding: 14px 18px;
      font-size: 16px;
      width: 100%;
    }
  }
}

.Disabled {
  opacity: 0.5;
  cursor: inherit;
  pointer-events: none;
}

.Error {
  color: red;
  font-size: 13px;
  input {
    border: 1px solid red;
  }
}

.Roistat {
  display: none;
}

.After {
    margin-top: 5px;
    font-size: 13px;
    color: #9294A9;
}

.Right {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content {
  position: relative;
}