.Product {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 8px 0 15px 0;
    position: relative;

    span {
      position: absolute;
      font-size: 14px;
      color: #000;
      font-weight: 700;
      left: 0;
      bottom: -32px;
    }
  }
}

.Body {
  display: flex;
  gap: 20px;
}

.Left {
  width: 35%;
}

.Right {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-bottom: 50px;
  gap: 30px;
}

@media only screen and (max-width: 991px) {
  .Body {
    gap: 20px;
    margin-bottom: 20px;
  }
  .Left {
      width: calc(100% - 300px);

  }
  .Right {
      width: 300px;
    margin-bottom: 20px;
  }
  .MainInfo {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .Body {
    flex-direction: column;
    margin-bottom: 0;
  }
  .Left {
    width: 100%;
  }
  .Right {
    width: 100%;
    margin-bottom: 0;
  }
}